import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MiddleWare from './https/MiddleWare'
import Layout from './components/Layout'
import Auth from './pages/Auth'
import { useSelector } from 'react-redux'
import Welcome from './pages/Welcome'
import Analytics from './pages/Analytics'
import { Staffs } from './pages/Staffs'
import { Clients } from './pages/Сlients'
import { ClientsDetails } from './pages/СlientsDetails'
import { ClientsView } from './pages/СlientsView'
import { Expenses } from './pages/Expenses'
import { PaymentHistory } from './pages/PaymentHistory'
import { Salary } from './pages/Salary'
import { Cashier } from './pages/Cashier'
import { Reporting } from './pages/Reporting'
import { Graphics } from './pages/Graphics'
import { ForAnAccountant } from './pages/ForAnAccountant'

function App() {
    const userPosition = useSelector((state: any) => state.user?.group?.name)
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="login"
                    element={
                        <MiddleWare>
                            <Auth />
                        </MiddleWare>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <MiddleWare>
                            <Layout />
                        </MiddleWare>
                    }
                >
                    <Route index path="home" element={<Analytics />} />
                    <Route path="staffs" element={<Staffs />} />
                    <Route path="expenses" element={<Expenses />} />
                    <Route
                        path="payment-history"
                        element={<PaymentHistory />}
                    />
                    <Route path="salaries" element={<Salary />} />
                    <Route path="cashier" element={<ForAnAccountant />} />
                    {/* <Route path="cashier" element={<Cashier />} /> */}
                    <Route path="clients/*">
                        <Route index element={<Clients />} />
                        <Route path="details" element={<ClientsDetails />} />
                        <Route path=":id" element={<ClientsView />} />
                    </Route>
                    <Route path="reporting" element={<Reporting />}></Route>
                    <Route path="graphics" element={<Graphics />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App
