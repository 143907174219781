import { $axios } from '../https/axiosInstance'
import { CreateCleanSearchParams, RemoveEmptyObj } from '../helpers/helpers'
import { useAsync } from 'react-async-hook'

export const PaymentHistoryService = {
    GetPaymentHistoryList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get(
              '/payment-history/list/' +
                    CreateCleanSearchParams(searchParams),
            )
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetPaymentHistoryManagerPercentage() {
        return useAsync(async () => {
            return await $axios.get('/payment-history/manager/percentage/')
        }, [])
    },
    GetPaymentHistoryCooperationPercentage() {
        return useAsync(async () => {
            return await $axios.get('/payment-history/cooperation/percentage/')
        }, [])
    },
    GetPaymentHistoryTypes() {
        return useAsync(async () => {
            return await $axios.get('/payment-history/types/')
        }, [])
    },
    GetPaymentHistoryStatuses() {
        return useAsync(async () => {
            return await $axios.get('/payment-history/statuses/')
        }, [])
    },
    GetPaymentHistoryTotal() {
        return useAsync(async () => {
            return await $axios.get('/payment-history/total/')
        }, [])
    },

    async UpdatePaymentHistory(paymentHistory: any) {
        return await $axios.put(
            `/payment-history/update/${paymentHistory.id}/`,
            RemoveEmptyObj(paymentHistory),
        )
    },
    async PartialUpdatePaymentHistory(paymentHistory: any) {
        return await $axios.patch(
            `/payment-history/update/${paymentHistory.id}/`,
            RemoveEmptyObj(paymentHistory),
        )
    },
    async CreatePaymentHistory(paymentHistory: any) {
        return await $axios.post(
            '/payment-history/create/',
            RemoveEmptyObj(paymentHistory),
        )
    },
    async DeletePaymentHistory(paymentHistory: any) {
        return await $axios.delete(`/payment-history/${paymentHistory.id}/`)
    },
    GetPaymentHistoryServicesList() {
        return useAsync(async () => {
            return await $axios.get('/payment-history/service/list/')
        }, [])
    },
    async CreatePaymentHistoryService(service: any) {
        return await $axios.post(
            `/payment-history/service/create/`,
            RemoveEmptyObj(service),
        )
    },
    async DeletePaymentHistoryService(id: any) {
        return await $axios.delete(`/payment-history/service/remove/${id}/`)
    },
}
