import { $axios } from "../https/axiosInstance";
import { CreateCleanSearchParams, RemoveEmptyObj } from "../helpers/helpers";
import { useAsync } from "react-async-hook";

export const StaffService = {
  GetStaffList(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/users/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },

  //   GetStaffWithoutPagination() {
  //     return useAsync(async () => {
  //       return await $axios.get("/staff/users/");
  //     }, []);
  //   },

  GetAllStaffList() {
    return useAsync(async () => {
      return await $axios.get("/staff/users/without-pagination/");
    }, []);
  },
  
  GetRoles() {
    return useAsync(async () => {
      return await $axios.get("/staff/roles/");
    }, []);
  },
  GetManagers() {
    return useAsync(async () => {
      return await $axios.get("/staff/users/managers/");
    }, []);
  },
  async UpdateStaff(staff: any) {
    return await $axios.put(
      `/staff/users/update/${staff.id}/`,
      RemoveEmptyObj(staff)
    );
  },
  async CreateStaff(staff: any) {
    return await $axios.post("/staff/users/create/", RemoveEmptyObj(staff));
  },
  async DeleteStaff(staff: any) {
    return await $axios.delete(`/staff/users/${staff.id}/`);
  },
};
