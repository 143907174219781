import React, { useEffect, useState } from 'react'
import Header from '../components/UserHeader'
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { ClientService } from '../service/ClientService'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { checkModalResponse } from '../helpers/helpers'
import SearchIcon from '@mui/icons-material/Search'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers'
import { useSnackbar } from '../helpers/SnackbarProvider'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { ru } from 'date-fns/locale/ru'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: '',
    },
    filter: {
        search: '',
        date: '',
        page: 1,
        size: 20,
        total_pages: 1,
    },
}

const modalInitialValues = {
    open: false,
    values: {
        id: '',
        reviews: [],
        files: [],
        full_name: '',
        contacts: '',
        address: '',
        date_of_birth: '',
        type_of_registration: '',
        service: '',
        niche: '',
        expected_amount: '',
        date_of_payment: '',
        site: '',
        domain_expiration_date: '',
        account_number: '',
        card_number: '',
        analytics: '',
        metric: '',
        goals: '',
        tm: '',
        reporting: '',
        acts: '',
        payment_invoice_number: '',
        case: '',
        comment: '',
        manager: '',
        status: '',
    },
    validation: {
        error: {
            full_name: false,
            contacts: false,
            address: false,
            date_of_birth: false,
            type_of_registration: false,
            service: false,
            niche: false,
            expected_amount: false,
            date_of_payment: false,
            site: false,
            domain_expiration_date: false,
            account_number: false,
            card_number: false,
            analytics: false,
            metric: false,
            goals: false,
            tm: false,
            reporting: false,
            acts: false,
            payment_invoice_number: false,
            case: false,
            comment: false,
            manager: false,
            status: false,
        },
        message: {
            full_name: '',
            contacts: '',
            address: '',
            date_of_birth: '',
            type_of_registration: '',
            service: '',
            niche: '',
            expected_amount: '',
            date_of_payment: '',
            site: '',
            domain_expiration_date: '',
            account_number: '',
            card_number: '',
            analytics: '',
            metric: '',
            goals: '',
            tm: '',
            reporting: '',
            acts: '',
            payment_invoice_number: '',
            case: '',
            comment: '',
            manager: '',
            status: '',
        },
    },
    requested: false,
    action: '',
    showPassword: false,
}

export const Clients = () => {
    const { openSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const user = useSelector((state: any) => state.userData.user.position.name)

    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {
                field: 'index',
                headerName: '№',
                width: 90,
                renderCell: (params: any) =>
                    params.api.getAllRowIds().indexOf(params.id) + 1,
            },
            ...(user !== 'Менеджер'
                ? [
                      {
                          field: 'manager',
                          headerName: 'Менеджер',
                          flex: 1,
                          renderCell: (params: any) =>
                              params.row.manager?.full_name,
                      },
                  ]
                : []),
            { field: 'full_name', headerName: 'ФИО', flex: 1 },
            { field: 'card_number', headerName: 'Номер карты', flex: 1 },
            {
                field: 'subscription_fee',
                headerName: 'Абонплата',
                flex: 1,
            },
            {
                field: 'date_of_payment',
                headerName: 'Дата оплаты',
                flex: 1,
                renderCell: (params: any) =>
                    params.row.date_of_payment
                        ? moment(params.row.date_of_payment).format(
                              'DD-MM-YYYY',
                          )
                        : '',
            },
            {
                field: 'actions',
                headerName: 'Действия',
                width: 110,
                renderCell: (params: any) => (
                    <div className="flex justify-start gap-2">
                        <IconButton
                            onClick={() =>
                                navigate({
                                    pathname: '/clients/details',
                                    search: createSearchParams({
                                        action: 'edit',
                                        client_id: params.row.id,
                                    }).toString(),
                                })
                            }
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setModal({
                                    ...modal,
                                    open: true,
                                    action: 'delete',
                                    values: {
                                        ...params.row,
                                    },
                                })
                            }}
                        >
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
    })

    const [modal, setModal] = useState(modalInitialValues)

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        setModal({
            ...modal,
            requested: true,
        })

        switch (modal.action) {
            case 'delete':
                ClientService.DeleteClient(modal.values)
                    .then(() => {
                        setModal(modalInitialValues)
                        tableList.execute()
                        openSnackbar('Успешно удалено', { variant: 'success' })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось удалить', { variant: 'error' })
                    })
                break

            default:
                break
        }
    }

    const tableList = ClientService.GetClientList(table.filter)
    const statusesList = ClientService.GetStatuses()

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }))
        } else {
            const data = tableList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                rows: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
            }))
        }
    }, [tableList.loading, tableList.error, tableList.result?.data])

    return (
        <>
            <Header pageTitle={'Клиенты'} />

            <div className="w-full flex justify-between items-center mt-[103px] mb-[40px]">
                <div className="flex items-center gap-[20px]">
                    <TextField
                        label="Поиск клиента"
                        variant="standard"
                        type="text"
                        value={table.filter.search}
                        onChange={(e) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    search: e.target.value,
                                },
                            })
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DatePicker
                            label="Месяц"
                            onChange={(newValue: any) => {
                                let formattedDate = ''
                                if (newValue) {
                                    formattedDate =
                                        moment(newValue).format('YYYY-MM')
                                }
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        date: formattedDate,
                                    },
                                })
                            }}
                            slotProps={{
                                textField: { variant: 'standard' },
                                field: { clearable: true },
                            }}
                            views={['year', 'month']}
                            minDate={new Date('2000-01-01')}
                            maxDate={new Date('2050-12-31')}
                        />
                    </LocalizationProvider>
                    <FormControl className="w-[120px]" variant="standard">
                        <InputLabel>Статусы</InputLabel>
                        <Select
                            label="Статусы"
                            required={true}
                            value={table.filter.status}
                            onChange={(event: any) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        status: event.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {!statusesList.loading &&
                                !statusesList.error &&
                                statusesList.result?.data.map(
                                    (status: any, index: number) => (
                                        <MenuItem key={index} value={status.id}>
                                            {status.name}
                                        </MenuItem>
                                    ),
                                )}
                        </Select>
                    </FormControl>
                </div>
                <div className="">
                    <Button
                        variant="contained"
                        color="black"
                        style={{
                            borderRadius: '100px',
                            padding: '16px 30px',
                            lineHeight: 'normal',
                        }}
                        onClick={() =>
                            navigate({
                                pathname: '/clients/details',
                                search: createSearchParams({
                                    action: 'add',
                                }).toString(),
                            })
                        }
                    >
                        Добавить клиента
                    </Button>
                </div>
            </div>

            <Box sx={{ width: '100%', marginBottom: '40px' }}>
                <DataGrid
                    rows={table.rows}
                    columns={table.columns}
                    checkboxSelection={false}
                    keepNonExistentRowsSelected
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    onRowDoubleClick={(params: any) =>
                        navigate(`/clients/${params.row.id}`)
                    }
                    loading={table.status.loading}
                    disableRowSelectionOnClick
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#979CFF',
                            color: '#FFF',
                        },
                        '.MuiDataGrid-iconButtonContainer': {
                            display: 'none',
                        },
                    }}
                />
            </Box>
            <div className="w-full flex gap-[20px] items-center justify-end">
                <div className="flex items-center gap-[10px]">
                    <p>Rows:</p>
                    <TextField
                        label=""
                        variant="outlined"
                        size="small"
                        type="number"
                        sx={{ width: '100px' }}
                        value={table.filter.size}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    size: event.target.value,
                                },
                            })
                        }}
                    />
                </div>
                <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={(event, value: number) => {
                        setTable({
                            ...table,
                            filter: {
                                ...table.filter,
                                page: value,
                            },
                        })
                    }}
                />
            </div>

            <Modal
                open={modal.open}
                onClose={() => setModal(modalInitialValues)}
            >
                <form
                    onSubmit={handleFormSubmit}
                    className="mainModal relative flex flex-col justify-start items-center"
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => setModal(modalInitialValues)}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[80px]">
                        {modal.action === 'delete' && 'Удалить клиента?'}
                    </h2>

                    <div className="w-full flex gap-[20px]">
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            fullWidth
                            variant="outlined"
                            color="black"
                            onClick={() => setModal(modalInitialValues)}
                        >
                            Отменить
                        </LoadingButton>
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            loading={modal.requested}
                            fullWidth
                            variant="contained"
                            color="black"
                            type="submit"
                        >
                            {modal.action === 'delete' && 'Удалить'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal>
        </>
    )
}
