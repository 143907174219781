import { useAsync } from "react-async-hook";
import { $axios } from "../https/axiosInstance";
import { CreateCleanSearchParams } from "../helpers/helpers";

export const GraphicsService = {
  GetGraphicsClientsList(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/clients-list/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  GetGraphicsSalaryList(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/manager-salaries/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  GetGraphicsManagerList(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/manager-precentage-sums/" +
          CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
};
