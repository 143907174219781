import React, { useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { logout } from "../store/slices/userSlice";
import { dispatch } from "../index";
import { LoadingButton } from "@mui/lab";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Header({ pageTitle }: { pageTitle: string }) {
  const user = useSelector((state: any) => state.userData.user);

  const modalInitialValues = {
    open: false,
    requested: false,
  };

  const [modal, setModal] = useState({
    ...modalInitialValues,
  });

  return (
    <div className="w-full flex justify-between items-center">
      <h1 className="text-[#282828] text-[40px] font-[700]">{pageTitle}</h1>
      <div className="flex gap-[20px] items-center">
        <Button
          variant="outlined"
          sx={{
            borderRadius: "100px",
            padding: "14px 24px",
            fontSize: "12px",
            lineHeight: "normal",
          }}
          color="black"
        >
          {user?.full_name ? user?.full_name : user?.username}
        </Button>

        <Button
          variant="outlined"
          sx={{
            borderRadius: "100%",
            padding: "10px",
            minWidth: "unset",
          }}
          color="black"
          onClick={() => {
            setModal({
              ...modal,
              open: true,
            });
          }}
        >
          <LogoutOutlinedIcon />
        </Button>
      </div>

      <Modal open={modal.open}>
        <div className="mainModal relative flex flex-col justify-start items-center">
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() => setModal(modalInitialValues)}
          >
            <CloseIcon />
          </IconButton>

          <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[80px]">
            Выйти
          </h2>

          <div className="w-full flex gap-[20px]">
            <LoadingButton
              style={{ borderRadius: "100px" }}
              fullWidth
              variant="outlined"
              color="black"
              onClick={() => setModal(modalInitialValues)}
            >
              Отменить
            </LoadingButton>
            <LoadingButton
              style={{ borderRadius: "100px" }}
              loading={modal.requested}
              fullWidth
              variant="contained"
              color="black"
              type="submit"
              onClick={() => dispatch(logout())}
            >
              Подвердить
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}
