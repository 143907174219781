import {$axios} from "../https/axiosInstance";
import {CreateCleanSearchParams, RemoveEmptyObj} from "../helpers/helpers";
import {useAsync} from "react-async-hook";

export const ExpenseService = {
    GetExpenseList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get(
                "/expense/list/" + CreateCleanSearchParams(searchParams)
            );
        }, [CreateCleanSearchParams(searchParams)]);
    },
    GetExpenseRegistrationTypes() {
        return useAsync(async () => {
            return await $axios.get("/expense/registration/types/");
        }, []);
    },
    GetExpenseTypes() {
        return useAsync(async () => {
            return await $axios.get("/expense/types/");
        }, []);
    },
    GetTotalAmountByCategories() {
        return useAsync(async () => {
            return await $axios.get("/expense/registration-type-total-amount/");
        }, [])
    },
    GetRegistrationTypes() {
        return useAsync(async () => {
            return await $axios.get("/expense/registration/types/");
        }, []);
    },
    async UpdateExpense(expense: any) {
        return await $axios.put(
            `/expense/update/${expense.id}/`,
            RemoveEmptyObj(expense)
        );
    },
    async CreateExpense(expense: any) {
        return await $axios.post("/expense/create/", RemoveEmptyObj(expense));
    },
    async DeleteExpense(expense: any) {
        return await $axios.delete(`/expense/${expense.id}/`);
    },
};
