import React, { useEffect, useState } from 'react'
import Header from '../components/UserHeader'
import {
    Box,
    FormControl,
    IconButton,
    MenuItem,
    Button,
    Modal,
    Pagination,
    Select,
    TextField,
    InputAdornment,
    InputLabel,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { CashierService } from '../service/CashierService'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { checkModalResponse } from '../helpers/helpers'
import { DatePicker } from '@mui/x-date-pickers'
import { useSnackbar } from '../helpers/SnackbarProvider'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { ru } from 'date-fns/locale/ru'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { Visibility } from '@mui/icons-material'

const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: '',
    },
    filter: {
        page: 1,
        size: 20,
        total_pages: 1,
        payment_date: moment(new Date()).format('YYYY-MM'),
        client_payment_date: 'define',
        search: '',
    },
}

const modalInitialValues = {
    open: false,
    values: {
        id: '',
        payment_made: '',
        invoices: [{ id: '', link: '' }],
    },
    validation: {
        error: {
            payment_made: false,
            invoices: [{ id: '', link: '' }],
        },
        message: {
            payment_made: '',
            invoices: [{ id: '', link: '' }],
        },
    },
    requested: false,
    action: '',
}

export const ForAnAccountant = () => {
    const { openSnackbar } = useSnackbar()

    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {
                field: 'full_name',
                headerName: 'Наименование',
                flex: 3,
                renderCell: (params: any) => params.row?.client?.full_name,
            },
            {
                field: 'itn',
                headerName: 'ИНН',
                flex: 3,
                renderCell: (params: any) => (
                    <div className="flex gap-2 items-center">
                        {params.row.client?.itn ? (
                            <div className="flex gap-1 items-center">
                                <ContentCopyIcon
                                    className="cursor-pointer py-1"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            params.row.client?.itn,
                                        )
                                        setSnackbarOpen(true)
                                    }}
                                />
                                {params.row.client?.itn}
                            </div>
                        ) : (
                            <div>{params.row.client?.itn}</div>
                        )}
                    </div>
                ),
            },
            {
                field: 'payment_date',
                headerName: 'Дата оплаты',
                flex: 2,
                renderCell: (params: any) =>
                    moment(params.row?.client?.date_of_payment)
                        .format('DD-MM-YYYY')
                        .toString(),
            },
            {
                field: 'invoices',
                headerName: 'Счет на оплату',
                flex: 3,
                renderCell: (params: any) => (
                    <div className="flex flex-col gap-[8px] whitespace-normal break-words">
                        {params.row?.invoices?.map(
                            (invoice: any, index: number) => (
                                <a
                                    key={index}
                                    href={invoice.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="underline text-[#979CFF] cursor-pointer"
                                >
                                    {`Счет N${index + 1}`}
                                </a>
                            ),
                        )}
                    </div>
                ),
            },

            {
                field: 'paid_for',
                headerName: 'Оплатили',
                flex: 2,
                renderCell: (params: any) =>
                    params.row?.balance
                        ? params.row?.paid_for + params.row?.balance
                        : params.row?.paid_for,
            },
            {
                field: 'payment_made',
                headerName: 'Оплата производится',
                flex: 2,
                renderCell: (params: any) => params.row.payment_made?.title,
            },
            {
                field: 'comment',
                headerName: 'Комментарии',
                flex: 2,
                renderCell: (params: any) => params.row.client?.comment,
            },
            {
                field: 'requisites',
                headerName: 'Реквизиты',
                flex: 2,
                renderCell: (params: any) => {
                    const string = params.row.client?.requisites
                        ? params.row.client?.requisites
                        : ''
                    const length = 50
                    const trimmedString =
                        string.length > length
                            ? string.substring(0, length - 3) + '...'
                            : string
                    return (
                        <div>
                            <p>{trimmedString}</p>
                            {string && string.length > 0 && (
                                <IconButton
                                    onClick={() =>
                                        setCredentialsModal((prev: any) => ({
                                            ...prev,
                                            open: true,
                                            content:
                                                params.row.client?.requisites,
                                        }))
                                    }
                                >
                                    <Visibility />
                                </IconButton>
                            )}
                        </div>
                    )
                },
            },
            {
                field: 'actions',
                headerName: '',
                flex: 1.3,
                renderCell: (params: any) => {
                    const colors: { [key: string]: string } = {
                        default: '#ccc',
                        green: '#38D464',
                        red: '#FF8768',
                        blue: '#979CFF',
                    }

                    return (
                        <div className="flex justify-start gap-2 editPen">
                            <FormControl
                                variant="standard"
                                className="colorSelect"
                            >
                                <Select
                                    value={params.row.color?.slug || 'default'}
                                    onChange={(event) => {
                                        const cashierID = params.row.id
                                        const updatedColorSlug =
                                            event.target.value

                                        const updatedData = {
                                            id: cashierID,
                                            color: updatedColorSlug,
                                        }

                                        CashierService.UpdateCashier(
                                            updatedData,
                                        )
                                            .then(() => {
                                                tableList.execute()
                                            })
                                            .catch((error) => {
                                                console.error(
                                                    'Error updating cashier:',
                                                    error,
                                                )
                                            })
                                    }}
                                    renderValue={(value) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    height: 10,
                                                    width: 10,
                                                    borderRadius: '50%',
                                                    bgcolor:
                                                        colors[
                                                            value as keyof typeof colors
                                                        ],
                                                    marginRight: 1,
                                                }}
                                            />
                                        </Box>
                                    )}
                                >
                                    {Object.entries(colors).map(
                                        ([colorSlug, colorValue]) => (
                                            <MenuItem
                                                key={colorSlug}
                                                value={colorSlug}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            height: 10,
                                                            width: 10,
                                                            borderRadius: '50%',
                                                            bgcolor: colorValue,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                </Box>
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>

                            <IconButton
                                onClick={() => {
                                    console.log(params.row.id)
                                    setModal({
                                        ...modal,
                                        open: true,
                                        action: 'edit',
                                        values: {
                                            id: params.row.id,
                                            // cashier_id: params.row.
                                            comment: params.row?.comment,
                                            payment_made:
                                                params.row?.payment_made,
                                            invoices: Array.isArray(
                                                params.row.invoices,
                                            )
                                                ? params.row.invoices.map(
                                                      (item: any) => ({
                                                          id: item.id,
                                                          link: String(
                                                              item.link,
                                                          ),
                                                      }),
                                                  )
                                                : [],

                                            balance: params.row.balance,
                                        },
                                    })
                                }}
                            >
                                <EditOutlinedIcon />
                            </IconButton>
                        </div>
                    )
                },
            },
        ],
    })

    const [credentialsModal, setCredentialsModal] = useState<any>({
        open: false,
        content: '',
    })

    const [modal, setModal] = useState<any>(modalInitialValues)

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        setModal({
            ...modal,
            requested: true,
        })

        const values = {
            ...modal.values,
        }

        switch (modal.action) {
            case 'edit':
                CashierService.UpdateCashier(values)
                    .then(() => {
                        setModal(modalInitialValues)
                        tableList.execute()
                        openSnackbar('Запись успешно изменена', {
                            variant: 'success',
                        })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось изменить запись', {
                            variant: 'error',
                        })
                    })

                break

            default:
                break
        }
    }

    const tableList = CashierService.GetCashierList(table.filter)

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }))
        } else {
            const data = tableList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                rows: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
            }))
        }
    }, [tableList.loading, tableList.error, tableList.result?.data])

    const clearBalance = () => {
        if (modal.values.id) {
            CashierService.ClearBalance(modal.values.id).then((resp) => {
                setModal(modalInitialValues)
                tableList.execute()
            })
        }
    }

    return (
        <>
            <Header pageTitle={'Для бухгалтера'} />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    ИНН скопирован !
                </Alert>
            </Snackbar>

            <div className="w-full flex justify-between items-center mt-[103px] mb-[40px]">
                <div className="flex items-center  gap-[20px]">
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DatePicker
                            className="w-[200px]"
                            label="Дата"
                            onChange={(newValue: any) => {
                                let formattedDate = ''
                                if (newValue) {
                                    formattedDate =
                                        moment(newValue).format('YYYY-MM')
                                }
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        payment_date: formattedDate,
                                    },
                                })
                            }}
                            value={table.filter.payment_date}
                            slotProps={{
                                textField: { variant: 'standard' },
                            }}
                            views={['year', 'month']}
                            minDate={new Date('2000-01-01')}
                            maxDate={new Date('2050-12-31')}
                        />
                    </LocalizationProvider>
                </div>

                <TextField
                    label="Поиск"
                    className="w-[269px]"
                    variant="standard"
                    type="text"
                    value={table.filter.search}
                    onChange={(e) => {
                        setTable({
                            ...table,
                            filter: {
                                ...table.filter,
                                search: e.target.value,
                            },
                        })
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <Box sx={{ width: '100%', marginBottom: '40px' }}>
                <DataGrid
                    rows={table.rows}
                    columns={table.columns}
                    checkboxSelection={false}
                    keepNonExistentRowsSelected
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    loading={table.status.loading}
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'}
                    getRowClassName={(params) => {
                        const colorSlug = params.row.color?.slug
                        if (colorSlug) {
                            return `color-row-${colorSlug}`
                        }
                        return ''
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#979CFF',
                            color: '#FFF',
                        },
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal !important',
                            wordWrap: 'break-word !important',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            display: 'flex',
                            alignItems: 'start',
                        },
                        '.MuiDataGrid-iconButtonContainer': {
                            display: 'none',
                        },
                    }}
                />
            </Box>
            <div className="w-full flex gap-[20px] items-center justify-end">
                <div className="flex items-center gap-[10px]">
                    <p>Rows:</p>
                    <TextField
                        label=""
                        variant="outlined"
                        size="small"
                        type="number"
                        sx={{ width: '100px' }}
                        value={table.filter.size}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    size: event.target.value,
                                },
                            })
                        }}
                    />
                </div>
                <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={(event, value: number) => {
                        setTable({
                            ...table,
                            filter: {
                                ...table.filter,
                                page: value,
                            },
                        })
                    }}
                />
            </div>

            <Modal
                open={modal.open}
                onClose={() => setModal(modalInitialValues)}
            >
                <form
                    onSubmit={handleFormSubmit}
                    className="mainModal relative flex flex-col items-center overflow-y-auto h-[97%] justify-center"
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => setModal(modalInitialValues)}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
                        {modal.action === 'add' && 'Добавить запись'}
                        {modal.action === 'edit' && 'Редактировать запись'}
                        {modal.action === 'delete' && 'Удалить запись?'}
                    </h2>

                    {modal.action !== 'delete' && (
                        <div className="mb-[50px] w-full">
                            <div className="w-full grid grid-cols-3 gap-[30px] mb-[30px]">
                                {/* <TextField
                  fullWidth
                  required
                  label="Оплатили"
                  variant="standard"
                  type={"number"}
                  value={modal.values.paid_for}
                  error={modal.validation.error.paid_for}
                  helperText={modal.validation.message.paid_for}
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        paid_for: e.target.value,
                      },
                    });
                  }}
                /> */}

                                {/* <FormControl variant="standard">
                  <InputLabel>Оплата производится</InputLabel>
                  <Select
                    label="Оплата производится"
                    required={true}
                    value={modal.values.payment_made}
                    error={modal.validation.error.payment_made}
                    onChange={(event: any) => {
                      setModal({
                        ...modal,
                        values: {
                          ...modal.values,
                          payment_made: event.target.value,
                        },
                      });
                    }}
                  >
                    {!patmentMadeList.loading &&
                      !patmentMadeList.error &&
                      patmentMadeList.result?.data.map(
                        (group: any, index: number) => (
                          <MenuItem key={index} value={group.id}>
                            {group.title}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  <FormHelperText>
                    {modal.validation.message.payment_made}
                  </FormHelperText>
                </FormControl> */}

                                <TextField
                                    fullWidth
                                    // required
                                    label="Оплата производится"
                                    variant="standard"
                                    type={'string'}
                                    value={modal.values.payment_made}
                                    error={modal.validation.error.payment_made}
                                    helperText={
                                        modal.validation.message.payment_made
                                    }
                                    onChange={(e) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                payment_made: e.target.value,
                                            },
                                        })
                                    }}
                                />

                                {/* <TextField
                  fullWidth
                  // required
                  label="Реквизиты"
                  variant="standard"
                  type={"string"}
                  value={modal.values.requisites}
                  error={modal.validation.error.requisites}
                  helperText={modal.validation.message.requisites}
                  onChange={(e) => {
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        requisites: e.target.value,
                      },
                    });
                  }}
                /> */}
                            </div>
                            <TextField
                                fullWidth
                                required={false}
                                label="Комментарий"
                                multiline
                                rows={3}
                                maxRows={4}
                                value={modal.values.comment}
                                error={modal.validation.error.comment}
                                helperText={modal.validation.message.comment}
                                onChange={(e) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            comment: e.target.value,
                                        },
                                    })
                                }}
                            />

                            <h2
                                className="text-[
                            4px] font-[600] text-[#1E1C2A] mb-[22px] mt-[26px]"
                            >
                                {modal.action === 'edit' && 'Счета на оплату'}
                            </h2>

                            {modal.values.invoices.length === 0 ? (
                                <div className="w-full flex gap-2">
                                    <TextField
                                        sx={{ minWidth: 250 }}
                                        variant="standard"
                                        className="mt-[0px] w-full"
                                        label="Ссылка"
                                        // required
                                        value=""
                                        onChange={(event) => {
                                            const newLink = event.target.value
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    invoices: [
                                                        { link: newLink },
                                                    ],
                                                },
                                            })
                                        }}
                                    />
                                </div>
                            ) : (
                                modal.values.invoices.map(
                                    (item: any, index: any) => (
                                        <div
                                            className="w-full flex gap-2"
                                            key={index}
                                        >
                                            <TextField
                                                sx={{ minWidth: 250 }}
                                                variant="standard"
                                                className="mt-[0px] w-full"
                                                label="Ссылка"
                                                required
                                                value={item.link || ''}
                                                onChange={(event) => {
                                                    const newLink =
                                                        event.target.value
                                                    const updatedPaymentMade = [
                                                        ...modal.values
                                                            .invoices,
                                                    ]
                                                    updatedPaymentMade[index] =
                                                        {
                                                            ...updatedPaymentMade[
                                                                index
                                                            ],
                                                            link: newLink,
                                                        }
                                                    setModal({
                                                        ...modal,
                                                        values: {
                                                            ...modal.values,
                                                            invoices:
                                                                updatedPaymentMade,
                                                        },
                                                    })
                                                }}
                                            />

                                            {modal.values.invoices?.length >
                                                1 && (
                                                <IconButton
                                                    size="large"
                                                    sx={{ padding: '16px' }}
                                                    onClick={() => {
                                                        const updatedPaymentMade =
                                                            [
                                                                ...modal.values
                                                                    .invoices,
                                                            ]
                                                        updatedPaymentMade.splice(
                                                            index,
                                                            1,
                                                        )
                                                        setModal({
                                                            ...modal,
                                                            values: {
                                                                ...modal.values,
                                                                invoices:
                                                                    updatedPaymentMade,
                                                            },
                                                        })
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </div>
                                    ),
                                )
                            )}

                            <div className="flex mt-[25px]">
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        const newPayment = { id: '', link: '' }
                                        const updatedPaymentsLinks = [
                                            ...(modal.values.invoices || []),
                                            newPayment,
                                        ]

                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                invoices: updatedPaymentsLinks,
                                            },
                                        })
                                    }}
                                >
                                    Добавить
                                </Button>
                            </div>

                            <div className="mt-[25px] w-full flex flex-col gap-2">
                                <TextField
                                    sx={{ minWidth: 250 }}
                                    variant="standard"
                                    className="mt-[0px] w-full"
                                    label="Баланс"
                                    // required
                                    value={modal.values.balance}
                                    onChange={(event) => {
                                        const balance = event.target.value
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                balance,
                                            },
                                        })
                                    }}
                                />
                                <Button
                                    style={{
                                        marginTop: '10px',
                                        marginLeft: 'auto',
                                        borderRadius: '100px',
                                        width: 'max-content',
                                    }}
                                    variant="outlined"
                                    color="black"
                                    onClick={clearBalance}
                                >
                                    Очистить баланс
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className="w-full flex gap-[20px]">
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            fullWidth
                            variant="outlined"
                            color="black"
                            onClick={() => setModal(modalInitialValues)}
                        >
                            Отменить
                        </LoadingButton>
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            loading={modal.requested}
                            fullWidth
                            variant="contained"
                            color="black"
                            type="submit"
                        >
                            {modal.action === 'delete' && 'Удалить'}
                            {modal.action === 'edit' && 'Готово'}
                            {modal.action === 'add' && 'Добавить'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal>

            <Modal
                open={credentialsModal.open}
                onClose={() =>
                    setCredentialsModal({ open: false, content: '' })
                }
            >
                <div className="mainModal">
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() =>
                            setCredentialsModal({ open: false, content: '' })
                        }
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 className="text-center text-[32px] font-bold">
                        Реквизиты
                    </h2>
                    <p className="mt-[30px]">{credentialsModal.content}</p>
                </div>
            </Modal>
        </>
    )
}
