import { $axios } from '../https/axiosInstance'
import { CreateCleanSearchParams, RemoveEmptyObj } from '../helpers/helpers'
import { useAsync, useAsyncCallback } from 'react-async-hook'

export const CashierService = {
    GetCashierList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get(
                '/cashier/list/' + CreateCleanSearchParams(searchParams),
            )
        }, [CreateCleanSearchParams(searchParams)])
    },

    GetRegistrationTypes() {
        return useAsync(async () => {
            return await $axios.get('/expense/registration/types/')
        }, [])
    },

    GetPaymentMade() {
        return useAsync(async () => {
            return await $axios.get('/payment_made/list/')
        }, [])
    },

    //   async UpdateCashier(cashier: any) {
    //     return await $axios.put(
    //       `/cashier/update/${cashier.id}/`,
    //       RemoveEmptyObj(cashier)
    //     );
    //   },

    async UpdateCashier(cashier: any) {
        return await $axios.put(
            `/cashier/update/${cashier.id}/`,
            RemoveEmptyObj(cashier),
        )
    },
    async CreateCashier(cashier: any) {
        return await $axios.post('/cashier/create/', RemoveEmptyObj(cashier))
    },
    async DeleteCashier(cashier: any) {
        return await $axios.delete(`/cashier/${cashier.id}/`)
    },
    async ClearBalance(id: any) {
        return await $axios.post(`/clear_balance/${id}/`)
    },
}
