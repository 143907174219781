import {combineReducers, configureStore} from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
    userData: userReducer
});

const persistConfig = {
    key: 'agama',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);


export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
}, )