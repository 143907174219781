import * as React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const accessRules: any = {
    '/home': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'manager',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'assistant_accountant',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/clients': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'manager',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'assistant_accountant',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/staffs': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/expenses': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },

        {
            position: 'assistant_accountant',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/payment-history': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'manager',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'assistant_accountant',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/graphics': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/salaries': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/payment-invoices': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/cashier': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'assistant_accountant',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'accountant',
            privileges: {
                add: true,
                edit: false,
                delete: true,
            },
        },
    ],
    '/accountant': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
    '/reporting': [
        {
            position: 'superuser',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'manager',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
        {
            position: 'assistant_accountant',
            privileges: {
                add: true,
                edit: true,
                delete: true,
            },
        },
    ],
}

const MiddleWare = ({ children }: any) => {
    const location = useLocation()
    const userData = useSelector((state: any) => state.userData)
    const isOnLoginPage = location.pathname.includes('login')

    // const user = useSelector(
    //   (state: any) => state.userData?.user?.position?.name
    // );

    if (userData.authed) {
        const userRole = userData.user.position?.slug
        if (
            (userRole && userRole === 'accountant' && isOnLoginPage) ||
            (userRole &&
                userRole === 'accountant' &&
                location.pathname === '/') ||
            (userRole &&
                userRole === 'accountant' &&
                location.pathname === '/home')
        ) {
            return <Navigate to="/cashier" replace />
        } else {
            if (isOnLoginPage) {
                return <Navigate to="/home" replace />
            }
            if (location.pathname === '/') {
                return <Navigate to="/home" replace />
            }
        }

        for (const pageName in accessRules) {
            if (location.pathname.includes(pageName)) {
                const allowedRoles = accessRules[pageName]
                if (
                    allowedRoles.some((role: any) =>
                        role.position.includes(userData.user.position?.slug),
                    )
                ) {
                    return children
                } else {
                    return <Navigate to="/denied-permission" replace />
                }
            }
        }

        return children
    }

    if (!userData.authed && !isOnLoginPage) {
        return <Navigate to="/login" replace />
    }

    return children
}

export default MiddleWare
