import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {theme} from "./helpers/muiCustomization";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {ThemeProvider} from "@mui/material";
import {persistStore} from "redux-persist";
import SnackbarProvider from "./helpers/SnackbarProvider";

let persistor = persistStore(store);
export const {dispatch} = store

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={store}>
                <SnackbarProvider>
                    <PersistGate loading={<>loading...</>} persistor={persistor}>
                        <App/>
                    </PersistGate>
                </SnackbarProvider>
            </Provider>
        </LocalizationProvider>
    </ThemeProvider>
);
